import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LIBRARY_SERVER_PREFIX}/opac`;
const endpoints = {
  getLibraryUnionCatalogueList: () => `${RESOURCE_ENDPOINT}/search-union-catalogue`,
  getLibraryWiseJournal: () => `${RESOURCE_ENDPOINT}/get-library-wise-journal`,
  getJournalByLibraryCatalogueId: () => `${RESOURCE_ENDPOINT}/get-journal-by-library-catalogue-id`,
  getLibraryByJournalCatalogueId: () => `${RESOURCE_ENDPOINT}/get-library-by-journal-catalogue-id`,
  getCountInfoByOrganogramId: () => `${RESOURCE_ENDPOINT}/get-count-info`,
}

export default class OpacSearchUnionCatalogueApi {

  public getLibraryUnionCatalogueList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getLibraryUnionCatalogueList();
    return HttpService.get(url, params, headers);
  }

  public getCountInfoByOrganogramId = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getCountInfoByOrganogramId();
    return HttpService.get(url, params, headers);
  }

  public getLibraryWiseJournal = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getLibraryWiseJournal();
    return HttpService.get(url, params, headers);
  }

  public getJournalByLibraryCatalogueId = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getJournalByLibraryCatalogueId();
    return HttpService.get(url, params, headers);
  }

  public getLibraryByJournalCatalogueId = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getLibraryByJournalCatalogueId();
    return HttpService.get(url, params, headers);
  }


}
