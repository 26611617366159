import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LIBRARY_SERVER_PREFIX}/library-dashboard`;
const endpoints = {
    getLibraryWiseDataCount: () => `${RESOURCE_ENDPOINT}/library-wise-data`,
}

export default class AdminDashboardApi {

  public getLibraryWiseDataCount = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getLibraryWiseDataCount();
    return HttpService.get(url, params, headers);
  }


}
