import React, {Suspense} from 'react';
import { BrowserRouter } from 'react-router-dom';

import ContextProvider from "./context";
import LazyLoader from "./components/Loading/LazyLoader";
import ErrorBoundary from "./components/Error/ErrorBoundary/ErrorBoundary";
import AppRoutes from "./routes/Routes";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
    return (
        <ContextProvider>
            <BrowserRouter>
                <Suspense fallback={<LazyLoader />}>
                    <ErrorBoundary>
                        <ScrollToTop>
                            <AppRoutes />
                        </ScrollToTop>
                    </ErrorBoundary>
                </Suspense>
            </BrowserRouter>
        </ContextProvider>
    );
}

export default App;
