import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LIBRARY_SERVER_PREFIX}/report`;
const endpoints = {
    getReportDocumentList: () => `${RESOURCE_ENDPOINT}/document-report`,
    getReportAccessionList: () => `${RESOURCE_ENDPOINT}/accession-report`,
    getReportLostDamageList: () => `${RESOURCE_ENDPOINT}/lost-damage-report`,
    getReportSubjectWiseTotalBookList: () => `${RESOURCE_ENDPOINT}/subject-wise-total-book-report`,
    getReportTitleWiseTotalBookList: () => `${RESOURCE_ENDPOINT}/title-wise-total-book-report`,
    getReportDocumentTypeWiseTotalBookList: () => `${RESOURCE_ENDPOINT}/document-type-wise-total-book-report`,
    getReportBookIssuedReturnList: () => `${RESOURCE_ENDPOINT}/book-issue-return-list-report`,
    getReportMostIssuedBookList: () => `${RESOURCE_ENDPOINT}/most-issued-book-list-report`,
    getReportBookDemandList: () => `${RESOURCE_ENDPOINT}/book-demand-report`,
    getReportBookSelectionList: () => `${RESOURCE_ENDPOINT}/book-selection-report`,
    getReportBookPurchaseList: () => `${RESOURCE_ENDPOINT}/book-purchase-report`,
    getReportBookReceiveList: () => `${RESOURCE_ENDPOINT}/book-receive-report`,
    getReportMemberAttendanceList: () => `${RESOURCE_ENDPOINT}/member-attendance-list-report`,
    getReportMemberAttendanceHistoryList: () => `${RESOURCE_ENDPOINT}/member-attendance-history-report`,
    getReportBookCataloguingList: () => `${RESOURCE_ENDPOINT}/book-cataloguing-report`,
    getReportNationalUnionCatalogueList: () => `${RESOURCE_ENDPOINT}/national-union-catalogue-report`,
    getReportNationalUnionCatalogueDetailsList: () => `${RESOURCE_ENDPOINT}/national-union-catalogue-details-report`,
    getNationalUnionCatalogueDetailsDataList: () => `${RESOURCE_ENDPOINT}/national-union-catalogue-details-data-report`,
    getJournalHoldingDataList: () => `${RESOURCE_ENDPOINT}/journal-holding-report`,
}

export default class LibraryReportApi {

  public getReportDocumentList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportDocumentList();
    return HttpService.get(url, params, headers);
  }

  public getReportAccessionList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportAccessionList();
    return HttpService.get(url, params, headers);
  }

 public getReportLostDamageList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportLostDamageList();
    return HttpService.get(url, params, headers);
  }

  public getReportSubjectWiseTotalBookList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportSubjectWiseTotalBookList();
    return HttpService.get(url, params, headers);
  }

  public getReportTitleWiseTotalBookList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportTitleWiseTotalBookList();
    return HttpService.get(url, params, headers);
  }


  public getReportDocumentTypeWiseTotalBookList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportDocumentTypeWiseTotalBookList();
    return HttpService.get(url, params, headers);
  }

  public getReportBookIssuedReturnList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportBookIssuedReturnList();
    return HttpService.get(url, params, headers);
  }

  public getReportMostIssuedBookList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportMostIssuedBookList();
    return HttpService.get(url, params, headers);
  }
  public getReportBookDemandList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportBookDemandList();
    return HttpService.get(url, params, headers);
  }
  public getReportBookSelectionList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportBookSelectionList();
    return HttpService.get(url, params, headers);
  }
  public getReportBookPurchaseList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportBookPurchaseList();
    return HttpService.get(url, params, headers);
  }
  public getReportBookReceiveList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportBookReceiveList();
    return HttpService.get(url, params, headers);
  }

  public getReportMemberAttendanceList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportMemberAttendanceList();
    return HttpService.get(url, params, headers);
  }

  public getReportMemberAttendanceHistoryList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportMemberAttendanceHistoryList();
    return HttpService.get(url, params, headers);
  }

  public getReportBookCataloguingList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportBookCataloguingList();
    return HttpService.get(url, params, headers);
  }

  public getReportNationalUnionCatalogueList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportNationalUnionCatalogueList();
    return HttpService.get(url, params, headers);
  }

  public getReportNationalUnionCatalogueDetailsList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getReportNationalUnionCatalogueDetailsList();
    return HttpService.get(url, params, headers);
  }
  public getNationalUnionCatalogueDetailsDataList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getNationalUnionCatalogueDetailsDataList();
    return HttpService.get(url, params, headers);
  }

  public getJournalHoldingDataList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getJournalHoldingDataList();
    return HttpService.get(url, params, headers);
  }

}
