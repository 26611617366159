import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LIBRARY_SERVER_PREFIX}/opac`;
const endpoints = {
  getLibraryCatalogueList: () => `${RESOURCE_ENDPOINT}/search-catalogue`,
  getLibraryCatalogueDetailsByCatalogueId: () => `${RESOURCE_ENDPOINT}/get-catalogue-details-by-id`,
}

export default class OpacSearchCatalogueApi {

  public getLibraryCatalogueList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getLibraryCatalogueList();
    return HttpService.get(url, params, headers);
  }

  public getLibraryCatalogueDetailsByCatalogueId = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getLibraryCatalogueDetailsByCatalogueId();
    return HttpService.get(url, params, headers);
  }

}
