import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LIBRARY_SERVER_PREFIX}/export`
const endpoints = {
    exportDocumentReport: () => `${RESOURCE_ENDPOINT}/document-report-export`,
    exportAccessionReport: () => `${RESOURCE_ENDPOINT}/accession-report-export`,
    exportDocumentTypeWiseTotalBookReport: () => `${RESOURCE_ENDPOINT}/document-type-wise-total-book-report-export`,
    exportSubjectWiseTotalBookReport: () => `${RESOURCE_ENDPOINT}/subject-wise-total-book-report-export`,
    exportTitleWiseTotalBookReport: () => `${RESOURCE_ENDPOINT}/title-wise-total-book-report-export`,
    exportLostDamageReport: () => `${RESOURCE_ENDPOINT}/lost-damage-report-export`,
    exportBookIssuedReturnedListReport: () => `${RESOURCE_ENDPOINT}/book-issue-return-list-report-export`,
    exportMostIssuedBookListReport: () => `${RESOURCE_ENDPOINT}/most-issued-book-list-report-export`,
    exportMemberAttendanceReport: () => `${RESOURCE_ENDPOINT}/member-attendance-report-export`,
    exportMemberAttendanceHistoryReport: () => `${RESOURCE_ENDPOINT}/member-attendance-history-report-export`,
    exportBookDemandReport: () => `${RESOURCE_ENDPOINT}/book-demand-report-export`,
    exportBookSelectionReport: () => `${RESOURCE_ENDPOINT}/book-selection-report-export`,
    exportBookPurchaseReport: () => `${RESOURCE_ENDPOINT}/book-purchase-report-export`,
    exportBookReceiveReport: () => `${RESOURCE_ENDPOINT}/book-receive-report-export`,
    exportBookCataloguingReport: () => `${RESOURCE_ENDPOINT}/book-cataloguing-report-export`,

}

export default class ExportGrantApi {
    public exportDocumentReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDocumentReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportAccessionReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportAccessionReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportDocumentTypeWiseTotalBookReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDocumentTypeWiseTotalBookReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportSubjectWiseTotalBookReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportSubjectWiseTotalBookReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportTitleWiseTotalBookReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportTitleWiseTotalBookReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportLostDamageReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportLostDamageReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportBookIssuedReturnedListReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportBookIssuedReturnedListReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportMostIssuedBookListReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportMostIssuedBookListReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportMemberAttendanceReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportMemberAttendanceReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportMemberAttendanceHistoryReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportMemberAttendanceHistoryReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportBookDemandReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportBookDemandReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportBookSelectionReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportBookSelectionReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportBookPurchaseReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportBookPurchaseReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportBookReceiveReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportBookReceiveReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportBookCataloguingReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportBookCataloguingReport();
        return HttpService.post(url, payload, params, headers);
    }

}

