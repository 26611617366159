import React, {createContext, useState} from 'react'
import initialState from './list.state';

export const ListContext = createContext<any>(initialState);

export const ListProvider = ({ children }) => {
  const [enumListContext, setEnumListContext] = useState<any[]>(initialState.enumListContext);
  const [lookupListContext, setLookupListContext] = useState<any[]>(initialState.lookupListContext);
  const [locationListContext, setLocationListContext] = useState<any[]>(initialState.locationListContext);
  const [organizationListContext, setOrganizationListContext] = useState<any[]>(initialState.organizationListContext);
  const [organogramListContext, setOrganogramListContext] = useState<any[]>(initialState.organogramListContext);
  const [userListContext, setUserListContext] = useState<any[]>(initialState.userListContext);
  const [groupListContext, setGroupListContext] = useState<any[]>(initialState.groupListContext);
  const [roleListContext, setRoleListContext] = useState<any[]>(initialState.roleListContext);
  const [employeeListContext, setEmployeeListContext] = useState<any[]>(initialState.employeeListContext);
  const [designationListContext, setDesignationListContext] = useState<any[]>(initialState.designationListContext);
  const [departmentListContext, setDepartmentListContext] = useState<any[]>(initialState.departmentListContext);
  const [productListContext, setProductListContext] = useState<any[]>(initialState.productListContext);
  const [libraryVendorListContext, setLibraryVendorListContext] = useState<any[]>(initialState.productListContext);

  const contextValue = {
    organizationListContext, setOrganizationListContext,
    organogramListContext, setOrganogramListContext,
    userListContext, setUserListContext,
    roleListContext, setRoleListContext,
    groupListContext, setGroupListContext,
    employeeListContext, setEmployeeListContext,
    locationListContext, setLocationListContext,
    departmentListContext, setDepartmentListContext,
    productListContext, setProductListContext,
    designationListContext, setDesignationListContext,
    lookupListContext, setLookupListContext,
    enumListContext, setEnumListContext,
    libraryVendorListContext, setLibraryVendorListContext
  };

  return (
      <ListContext.Provider value={contextValue}>
        {children}
      </ListContext.Provider>
  );
};

export default ListProvider;
