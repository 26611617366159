// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";

// Core Server
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _ComponentApi from "./Core/Component.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _TemplateApi from "./Core/Template.api";
import _HelpApi from "./Core/Help.api";
import _ActivityLogApi from "./Core/ActivityLog.api";
import _AuditLogApi from "./Core/AuditLog.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DesignationApi from "./Auth/Designation.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";

// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _ServicesApi from "./Cms/Services.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";

// Library
import _ExampleApi from "./Library/Example.api";
import _LibraryApi from "./Library/Library.api";
import _LibraryAuthorApi from "./Library/LibraryAuthor.api";
import _LibraryVendorApi from "./Library/LibraryVendor.api";
import _BookDemandApi from "./Library/BookDemand.api";
import _DemandBookDetailApi from "./Library/DemandBookDetail.api";
import _LibraryMemberRegistrationApi from "./Library/LibraryMemberRegistration.api";
import _MemberCardIssueApi from "./Library/MemberCardIssue.api";
import _JournalCatalougeApi from "./Library/JournalCatalouge.api";
import _LibraryCatalougeApi from "./Library/LibraryCatalouge.api";
import _JournalHoldingApi from "./Library/JournalHolding.api";
import _LibraryPolicy from "./Library/LibraryPolicy.api";
import _CirculationCheckOutApi from "./Library/CirculationCheckOut.api";
import _CataloguingApi from "./Library/Cataloguing.api";
import _CatalougeSubjectApi from "./Library/CataloguingSubject.api";
import _CataloguingAuthorApi from "./Library/CataloguingAuthor.api";
import _CataloguingCorporateAuthorApi from "./Library/CataloguingCorporateAuthor.api";
import _CataloguingAccessionApi from "./Library/CataloguingAccession.api";
import _CataloguingMappingApi from "./Library/CataloguingMapping.api";
import _BookSelectionApi from "./Library/BookSelection.api";
import _BookSelectionDetailsApi from "./Library/BookSelectionDetails.api";
import _LibraryLostDamageApi from "./Library/LibraryLostDamageApi.api";
import _LibraryBookReservationApi from "./Library/LibraryBookReservationApi.api";
import _LibraryMemberReminderApi from "./Library/LibraryMemberReminderApi.api";
import _BookPurchaseApi from "./Library/BookPurchase.api";
import _BookPurchaseDetailsApi from "./Library/BookPurchaseDetails.api";
import _BookIssueApi from "./Library/BookIssue.api";
import _ManualInvoiceApi from "./Library/ManualInvoice.api";
import _BookReceiveApi from "./Library/BookReceive.api";
import _BookReceiveDetailsApi from "./Library/BookReceiveDetails.api";
import _MemberAttendanceApi from "./Library/MemberAttendance.api";
import _MemberAttendanceDetailsApi from "./Library/MemberAttendanceDetails.api";

import _OpacSearchCatalogueApi from "./Library/OpacSearchCatalogue.api";
import _OpacSearchUnionCatalogueApi from "./Library/OpacSearchUnionCatalogue.api";
import _AdminDashboardApi from "./Library/AdminDashboard.api";
import _LibraryReportApi from "./Library/LibraryReportApi.api";
import _ExportLibraryReportApi from "./Library/ExportLibraryReport.api";

// Util
import _NotificationApi from "./Util/Notification.api";

// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();

// Core Server
export const FackApi = new _FackApi();
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const ComponentApi = new _ComponentApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const TemplateApi = new _TemplateApi();
export const HelpApi = new _HelpApi();
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const ServicesApi = new _ServicesApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// Library
export const ExampleApi = new _ExampleApi();
export const LibraryApi = new _LibraryApi();
export const LibraryAuthorApi = new _LibraryAuthorApi();
export const LibraryVendorApi = new _LibraryVendorApi();
export const BookDemandApi = new _BookDemandApi();
export const DemandBookDetailApi = new _DemandBookDetailApi();
export const LibraryMemberRegistrationApi = new _LibraryMemberRegistrationApi();
export const MemberCardIssueApi = new _MemberCardIssueApi();
export const JournalCatalougeApi = new _JournalCatalougeApi();
export const LibraryCatalougeApi = new _LibraryCatalougeApi();
export const JournalHoldingApi = new _JournalHoldingApi();
export const LibraryPolicyApi = new _LibraryPolicy();
export const CirculationCheckOutApi = new _CirculationCheckOutApi();
export const CataloguingApi = new _CataloguingApi();
export const CatalougeSubjectApi = new _CatalougeSubjectApi();
export const CataloguingAuthorApi = new _CataloguingAuthorApi();
export const CataloguingCorporateAuthorApi = new _CataloguingCorporateAuthorApi();
export const CataloguingAccessionApi = new _CataloguingAccessionApi();
export const CataloguingMappingApi = new _CataloguingMappingApi();
export const BookSelectionApi = new _BookSelectionApi();
export const BookSelectionDetailsApi = new _BookSelectionDetailsApi();
export const LibraryLostDamageApi = new _LibraryLostDamageApi();
export const LibraryBookReservationApi = new _LibraryBookReservationApi();
export const LibraryMemberReminderApi = new _LibraryMemberReminderApi();
export const BookPurchaseApi = new _BookPurchaseApi();
export const BookPurchaseDetailsApi = new _BookPurchaseDetailsApi();
export const BookIssueApi = new _BookIssueApi();
export const ManualInvoiceApi = new _ManualInvoiceApi();
export const BookReceiveApi = new _BookReceiveApi();
export const BookReceiveDetailsApi = new _BookReceiveDetailsApi();
export const MemberAttendanceApi = new _MemberAttendanceApi();
export const MemberAttendanceDetailsApi = new _MemberAttendanceDetailsApi();

export const OpacSearchCatalogueApi = new _OpacSearchCatalogueApi();
export const OpacSearchUnionCatalogueApi = new _OpacSearchUnionCatalogueApi();
export const AdminDashboardApi = new _AdminDashboardApi();
export const LibraryReportApi = new _LibraryReportApi();
export const ExportLibraryReportApi = new _ExportLibraryReportApi();

