// export const DEV = process.env.NODE_ENV === 'development';
export const ENV_PROD = false;
export const ENV_DEV = true;
export const ENV_TEST = false;
export const LOG_ENABLED = true;

// Local Server
export const BASE_URL = 'https://library.most.gov.bd';
export const API_GATEWAY_URL = 'https://gateway.most.gov.bd/';
export const CDN_URL = 'https://cdn.most.gov.bd/';
export const MEDIA_SOURCE = 'https://cdn.most.gov.bd/image/';
export const WEB_SOCKET_URL = 'https://socket.most.gov.bd';

export const SECURITY_UI_URL = 'https://services.most.gov.bd';
export const USER_PANEL_UI_URL = 'https://accounts.most.gov.bd';
export const DOCS_UI_URL = 'https://docs.most.gov.bd';

export const GRANT_UI_URL = 'https://grant.most.gov.bd';
export const LICENSE_UI_URL = 'https://license.most.gov.bd';
export const LIBRARY_UI_URL = 'https://library.most.gov.bd';
export const RESEARCH_UI_URL = 'https://research.most.gov.bd';
export const ETICKET_UI_URL = 'https://eticket.most.gov.bd';
export const PROCESS_LEASE_UI_URL = 'https://process.most.gov.bd';
export const ACCOMMODATION_UI_URL = 'https://accomodation.most.gov.bd';
export const TEST_CONSULTANCY_UI_URL = 'https://test-service.most.gov.bd';
export const HEALTH_UI_URL = 'https://health.most.gov.bd';
export const TRAINING_UI_URL = 'https://training.most.gov.bd';
export const VIRTUAL_GALLERY_UI_URL = 'https://virtual-gallery.most.gov.bd';

export const AUTH_SERVER_PREFIX = "auth";
export const CORE_SERVER_PREFIX = "core";
export const CDN_SERVER_PREFIX = "cdn";
export const UTIL_SERVER_PREFIX = "util";
export const CMS_SERVER_PREFIX = "cms";
export const GRANT_SERVER_PREFIX = "grant";
export const LICENSE_SERVER_PREFIX = "license";
export const ETICKET_SERVER_PREFIX = "eticket";
export const LIBRARY_SERVER_PREFIX = "library";
export const VIRTUAL_GALLERY_SERVER_PREFIX = "vgallery";
export const ACCOMMODATION_SERVER_PREFIX = "accommodation";
export const PROCESS_LEASE_SERVER_PREFIX = "process-lease";
export const RESEARCH_SERVER_PREFIX = "research";
export const TRAINING_SERVER_PREFIX = "training";
export const TEST_CONSULTANCY_SERVER_PREFIX = "testing";
export const HEALTH_SERVER_PREFIX = "health";
